var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, width: "450" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Confirm Upload ")]),
          _c("v-card-text", [
            _vm._v(' Upload file "' + _vm._s(_vm.file.name) + '"? ')
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "grey darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.confirm } },
                [_vm._v(" Confirm ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }