<template>
  <v-dialog :value="value" persistent max-width="900px">
    <v-container fluid class="ma-0 pa-0">
      <v-card>
        <v-card-text class="py-4 pr-0">
          <v-toolbar dense flat>
            <v-icon class="mr-2 ml-0">mdi-file-upload-outline</v-icon>
            <v-toolbar-title class="title">
              Upload Contract
            </v-toolbar-title>
            <v-col cols="auto" class="ml-auto pr-1">
              <v-btn color="info" @click="downloadTemplate">
                <v-icon left>mdi-file-download-outline</v-icon>
                Template
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pr-0">
              <v-btn 
                color="primary"
                :loading="loading" 
                :disabled="loading || !file || (useVendorSelections && (!selectedVendor || !selectedSubvendor))"
                @click="uploadFile">
                <v-icon left>mdi-file-upload-outline</v-icon>
                {{ reupload ? "Reupload" : "Upload" }}
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pr-0">
              <v-btn icon @click="$emit('closeUpload')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-toolbar>
          <v-container fluid>
            <p class="pt-0 mt-0 pl-2 ml-2" style="color: grey; font-size: 16px">
              {{ reupload ? "Reupload the contract file." : "Create promotions via contract file." }}
            </p>
            <template>
              <v-form ref="form" lazy-validation v-model="valid">
                <v-row dense>
                  <v-col cols="8">
                    <v-file-input
                      v-model="file"
                      outlined 
                      dense
                      hide-details="auto" 
                      truncate-length="100"
                      label="File"
                      clearable
                      accept=".xlsx"
                      @change="handleFile">
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row v-if="useVendorSelections" class="mt-4" dense>
                  <v-col cols="6">
                    <VendorSingleSelect
                      class="ml-8"
                      :selectedVendor.sync="selectedVendor"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="selectedSubvendor"
                      :disabled="subvendorDisabled"
                      class="ml-8 mr-8"
                      :items="subvendors"
                      item-text="name"
                      item-value="id"
                      label="Subvendor"
                      :loading="loadingSubvendors"
                      clearable
                      background-color="#fff"
                      hide-no-data
                      return-object
                      dense
                      outlined
                      hide-details="auto">
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
    <UploadDialog v-if="dialog" v-model="dialog" :file="file" @cancel="closeUploadDialog"
      @confirmUpload="confirmUpload">
    </UploadDialog>
  </v-dialog>
</template>
<script lang="js">
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import PartyRel from '@/axios/party-relationship-endpoint.js'
import UploadDialog from '@/components/contracts/UploadDialog'
import VendorSingleSelect from '@/components/VendorSingleSelect'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
export default {
  name: 'ContractUpload',
  data() {
    return {
      vendors: [],
      subvendors: [],
      selectedVendor: null,
      selectedSubvendor: null,
      loadingSubvendors: false,
      file: null,
      newFile: false,
      valid: true,
      loading: false,
      dialog: false
    }
  },
  mixins: [displayAlert, userAccess, utils],
  components: { UploadDialog, VendorSingleSelect },
  props: {
    value: Boolean,
    reupload: {
      type: Boolean,
      default: false
    },
    uploadId: {
      type: String,
      default: ""
    }
  },
  computed: {
    subvendorDisabled () {
      return !this.selectedVendor
    },
    useVendorSelections () {
      return this.tenant === 'pricechopper'
    }
  },
  watch: {
    selectedVendor: {
      async handler(newValue) {
        if (newValue) {
          await this.updateSubvendors()
        }
      }
    },
  },
  methods: {
    handleFile(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.file.upload_file = e.target.result
        }
        reader.readAsArrayBuffer(file)
      }
    },
    reset() {
      this.file = null
    },
    uploadFile() {
      this.dialog = true
    },
    closeUploadDialog() {
      this.dialog = false
    },
    confirmUpload() {
      this.closeUploadDialog()
      this.upload()
    },
    async upload() {
      try {
        await this.processFile(this.file)
        this.emitAlert(true, 'success', 'File(s) have been successfully uploaded and are now being processed.')
        this.$emit('closeUpload', true)
        this.reset()
      } catch (err) {
        this.handleError(err)
      }
    },
    async processFile(file) {
      const blob = new Blob([file.upload_file], { type: `${file.type}` })
      const formData = new FormData()
      const pathEnv = `${process.env.VUE_APP_FILE_PATH}`
      const today = (new Date()).toISOString().split('T')[0]

      formData.append('path', `${pathEnv}/${this.tenant}/uploads/${today}/`)
      formData.append('name', `${file.name}`)
      formData.append('upload_file', blob, `${file.name}`)
      if (this.useVendorSelections && this.selectedVendor && this.selectedSubvendor) {
        const payload = { vendor_party_id: this.selectedVendor.id, subvendor_party_id: this.selectedSubvendor.id }
        formData.append('payload', JSON.stringify(payload))
      }
      if (this.reupload) {
        return ContractUpload.rerunContractUpload(formData, this.uploadId)
      } else {
        return ContractUpload.uploadContract(formData)
      }
    },
    async downloadTemplate() {
      try {
        this.emitAlert(true, 'info', "Template downloading")
        const response = await ContractUpload.downloadTemplate()
        const url = response.data.url
        window.open(url, '_blank')
      } catch (err) {
        console.error(err)
        this.emitAlert(true, 'error', "Error while downloading template")
      }
    },
    async updateSubvendors() {
      this.loadingSubvendors = true
      try {
        this.selectedSubvendor = null
        this.subvendors = []
        const subvendorParties = await this.getDownstreamSubvendorParties(this.selectedVendor.id)
        if (subvendorParties.length > 0) {
          this.subvendors = subvendorParties.sort((a, b) => a.name.localeCompare(b.name))
        }
      } catch (err) {
        this.handleError(err)
      }
      this.loadingSubvendors = false
    },
    async getDownstreamSubvendorParties (vendor_id) {
      const downstreamSubvendorRes = await PartyRel.downstreamRelationshipSearch({ to_party_ids: [vendor_id] })
      if (downstreamSubvendorRes.data?.length !== 0) {
        const downstreamSubvendors = downstreamSubvendorRes.data
          .filter(rel => rel.from_rel_type === 'CHILD_VENDOR_FOR')
          .map(rel => {
            let subvendor = {}
            subvendor.name = rel.from_party_name
            subvendor.party_name = `${subvendor.name}`
            subvendor.id = rel.from_party_id
            return subvendor
          })
        return downstreamSubvendors
      } else {
        return []
      }
    },
  }
}
</script>