<template>
    <v-dialog :value="value" width="450" @click:outside="$emit('cancel')">
      <v-card>
        <v-card-title>
          Confirm Upload
        </v-card-title>
        <v-card-text>
          Upload file "{{ file.name }}"?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey darken-1" text @click="$emit('cancel')">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="confirm">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'UploadDialog',
    mixins: [],
    props: {
      value: Boolean,
      file: File
    },
    methods: {
      confirm() {
        this.$emit('confirmUpload')
      }
    }
  }
  </script>